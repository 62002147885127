import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TimelineLite, Elastic } from "gsap/all"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../scss/templates/categories.scss"

class Categories extends React.Component {
  constructor(props) {
    super(props)
    this.postTween = new TimelineLite({delay: 0.2})
    this.postItems = []
  }
  componentDidMount() {
    this.postTween.staggerTo(this.postItems, 2 , { ease: Elastic.easeOut.config(1, 0.4) , y:0, opacity: 1} , 0.1)
  }
  render() {
    const { data } = this.props
    const catName = data.allWordpressPost.edges[0].node.categories[0].name
    const posts = data.allWordpressPost.edges

    return (
      <Layout location={this.props.location} crumbLabel={catName}>
        <SEO
          title={catName}
          description={catName}
          keywords={catName}
        />
        <div className="categoriesPageWrap">
          <div className="categoriesPage">

            <h2 className="categoriesSectionTitle">
              <span className="categoriesSectionTitle_l">Category</span>
              <span className="categoriesSectionTitle_s">{catName}</span>
            </h2>
            <ul className="categoriesBlogList">
              {posts.map(({ node }, i) => (
                <li key={node.id} className="categoriesBlogList_item" ref={li => this.postItems[i] = li}>
                  <AniLink fade duration={1} to={`/${node.slug}`} className="categoriesBlogList_img" >
                    {node.featured_media && (
                      <Img
                        fluid={node.featured_media.localFile.childImageSharp.fluid}
                        alt={node.title}
                      />
                    )}
                  </AniLink>
                  <div className="categoriesBlogInfo">
                    <span className="categoriesBlogInfoMeta">
                      <span className="categoriesBlogInfoMeta_date">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g><g><rect className="cls-1" width="48" height="48" fill="none"/></g><g className="categoriesBlogInfoMeta_date-icon"><path className="cls-2" d="M16,10H8a2,2,0,0,0-2,2V40a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H38" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" /><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="22" y1="10" x2="32" y2="10"/><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="16" y1="6" x2="16" y2="14"/><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="32" y1="6" x2="32" y2="14"/><line className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px" x1="6" y1="21" x2="34" y2="21"/></g></g></svg>
                        <span className="categoriesBlogInfoMeta_date-txt">{node.date}</span>
                      </span>
                      {node.date < node.modified &&
                        <span className="categoriesBlogInfoMeta_modified">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g><g><rect className="cls-1" width="48" height="48" fill="none"/></g><g className="categoriesBlogInfoMeta_modified-icon"><polyline className="cls-2" points="17.18 15 8.18 15 8.18 6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px"/><path className="cls-2" d="M10.58,12A18,18,0,1,1,6.23,26.88" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4px"/></g></g></svg>
                          <span className="categoriesBlogInfoMeta_modified-txt">{node.modified}</span>
                        </span>
                      }
                      <span className="categoriesBlogInfoMeta_cat" >
                        <span>{node.categories[0].name}</span>
                      </span>
                    </span>
                    <AniLink fade duration={1}  to={`/${node.slug}`} >
                      <h3 className="categoriesBlogInfo_title" dangerouslySetInnerHTML={{ __html: node.title }} />
                    </AniLink>
                  </div>

                </li>
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}
export default Categories

export const query = graphql`
	query ($category: String!) {
		allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: $category}}}}) {
			edges {
				node {
					id
					excerpt
					title
					slug
          date(formatString: "YYYY.MM.DD")
          modified(formatString: "YYYY.MM.DD")
					categories {
            name
            slug
					}
					featured_media {
						localFile {
							childImageSharp {
								fluid(maxWidth: 1000) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`